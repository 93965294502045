<template>
<div class="col product-card --box-shadow-1" :class="{'--selected': isSelected(value), 'hovered-card': hovered}"
  :style="{color: textColor, backgroundColor: bgColor}"
  @mouseover="hovered = true"
  @mouseleave="hovered = false" @click="handleClick">
  <PromoBanner v-if="product.promo.length" class="product-card-promo" :height="'32px'" :width="'32px'" :font-size="'8px'" :promo="product.promo"></PromoBanner>
  <img class="product-img" :src="product.imgs[0]" />
  <div class="product-desc">
    <p class="product-lable">{{ product.name }}</p>
    <product-price :price="product.price" :promo="product.promo" :font-size="'10px'"></product-price>
  </div>
  <div class="shop-btn" v-if="isSelected(value)">
    <a v-if="product.shopee" :href="product.shop" target=”_blank” @click="handleLinkClick" class="btn btn-ghost btn-buy-now btn-shopee">
      <svg class="shopee-logo" viewBox="0 0 113 126" xmlns="http://www.w3.org/2000/svg"><path d="M76.968 94.081c-.741 6.073-4.447 10.939-10.187 13.374-3.194 1.356-7.476 2.086-10.871 1.856-5.279-.2-10.241-1.478-14.812-3.809-1.664-.848-4.104-2.525-5.943-4.058-.42-.348-.635-.66-.232-1.228.43-.645 2.13-3.102 2.398-3.507.362-.552.956-.58 1.5-.153.075.057.628.486.74.569 4.4 3.423 10.076 5.994 16.384 6.235 7.955-.108 13.726-3.65 14.757-9.136 1.135-6.046-3.69-11.231-12.98-14.124-2.953-.92-10.38-3.872-11.75-4.672-6.474-3.77-9.488-8.717-9.058-14.807.657-8.438 8.534-14.762 18.53-14.804 4.744-.01 9.194 1.036 13.159 2.695 1.459.61 4.176 2.066 5.145 2.785.677.494.625 1.046.358 1.474-.395.656-1.57 2.483-2.043 3.245-.345.523-.773.583-1.38.2-5.112-3.41-10.37-4.567-15.103-4.661-6.828.134-11.978 4.165-12.316 9.691-.09 4.992 3.729 8.613 11.833 11.378C71.83 77.964 78.17 84.24 76.968 94.08zM56.32 7.34c10.83 0 19.66 10.208 20.073 22.986H36.248C36.66 17.548 45.489 7.34 56.32 7.34zM97.44 125.687c5.72-.155 10.355-4.776 10.844-10.504l.037-.692 4.05-81.638v-.001a2.402 2.402 0 0 0-2.4-2.526H83.95C83.312 13.454 71.185 0 56.32 0 41.455 0 29.33 13.454 28.69 30.326H2.632a2.402 2.402 0 0 0-2.35 2.588H.28l3.696 81.319.055 1.02c.564 5.658 4.7 10.215 10.322 10.425h.002l82.669.013.414-.004z" fill-rule="evenodd"></path></svg>
      <span>Buy Now</span>
    </a>
    <a v-else-if="product.shop" :href="product.shop" target=”_blank” @click="handleLinkClick" class="btn btn-ghost btn-buy-now">
      <img class="cl-logo" src="https://shop.enigma-audio.com/wp-content/uploads/2023/06/cropped-enigmabanner-copy.jpg" />
      <span>Buy Now</span>
    </a>
    <div v-else class="btn btn-buy-now --disabled">
      <span>Out of Stocks</span>
    </div>
  </div>
</div>
</template>

<script>
import PromoBanner from '@/public/PromoBanner.vue'
import ProductPrice from '@/public/ProductPrice.vue'
export default {
  name: 'ProductCard',
  data: () => {
    return {
      hovered: false
    }
  },
  props: {
    product: Object,
    value: String,
    color: String,
    isSelected: Function,
    add: Function,
    remove: Function,
  },
  computed:{
    textColor() {
      if (this.isSelected(this.value) && !this.hovered)
        return '#ffffff'
      else
        return '#444444'
    },
    bgColor() {
      if ( this.isSelected(this.value)&& !this.hovered)
        return '#444444'
      else
        return 'transparent'
    }
  },
  methods: {
    handleClick() {
      this.hovered = false
      this.isSelected(this.value) ? this.remove(this.value) : this.add(this.value)
    },
    handleLinkClick(event) {
      this.hovered = false
      event.stopPropagation()
    },
  },
  components: {
    PromoBanner,
    ProductPrice,
  },
}
</script>

<style>

.shopee-logo {
  width: auto;
  height: 16px;
  fill: #ee4d2d;
  display: inline-block;
  position: relative;
  margin: -2px;
  margin-right: 3px;
}

.cl-logo {
  width: auto;
  height: 20px;
  display: inline-block;
  position: relative;
  margin: -6px;
  margin-right: 2px;
}

.btn.btn-buy-now {
  display: block;
  background-color: #ffffff;
  font-size: 14px;
  position: absolute;
  margin-top: -80px;
  margin-left: 0;
  padding-left: 0px;
  padding-right: 0px;
  width: 148px;
}

.btn.btn-buy-now.--disabled {
  width: 150px;
  border: none;
}

.btn.btn-buy-now span {
  padding: 0;
  padding-left: 2px;
}

.btn-buy-now.btn-shopee {
  color: #ee4d2d;
  background-color: #ffffff;
  border: 1px solid #ee4d2d;
}

.btn.btn-buy-now:hover {
  background-color: #444444;
}

.btn-buy-now.btn-shopee:hover {
  color: #ffffff;
  background-color: #ee4d2d;
  border: 1px solid #ee4d2d;
}

.btn-buy-now.btn-shopee:hover .shopee-logo {
  fill: #ffffff;
}

.btn.btn-buy-now.--disabled > span {
  color: #444444;
}

.product-card {
  margin: 8px;
  width: 150px;
  display: inline-flex;
  text-shadow: none;
  color: #444444;
  cursor: pointer;
  border-radius: 4px;
  flex-flow: column;
  justify-content: space-evenly;
  flex: 0 0 auto;
  position: relative;
}

.product-card.--selected .product-img {
  opacity: 0.4;
}

.product-card-promo {
  position: absolute;
  top: 4px;
  right: 4px;
}

.product-img {
  height: 100px;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.product-lable {
  font-size: 12px;
  margin: 0;
}

.hovered-card {
  opacity: 0.6;
  color: white;
  background-color: #444444;
  border: 1px solid;
}

.product-card.--selected.hovered-card {
  background-color: white;
  opacity: 1;
  color: #444444;
  border: none;
}

.product-desc {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card .discount-price, .product-card .price-tag {
  margin-left: 4px;
}

.product-card .discount-price {
  position: absolute;
  margin-top: 40px;
  margin-left: 16px;
}

.product-card .product-price {
  margin-top: -8px !important;
}

@media (max-width: 766px) {
  .product-img {
    height: 90px;
    object-fit: cover;
  }

  .product-card {
    width: 135px;
    margin: 0 7px;
    position: relative;
  }

  .product-lable {
    font-size: 12px;
    margin: 0;
  }

  .btn.btn-buy-now {
    margin-top: -110px;
    margin-left: 10px;
    width: 115px;
    padding: 9px 0;
  }

  .btn.btn-buy-now.--disabled {
    width: 115px;
  }

  .product-desc { 
    padding: 10px 0;
  }
}
</style>
