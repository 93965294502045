<template>
  <div class="gallery-container view-container">
    <div class="gallery-wrapper view-wrapper">

      <page-wrapper id="p1" class="--spacial-wrapper-1" :direction="'column'">
        <div class="gallery-p1-content-wrapper">
          <div class="gallery-p1-text-wrapper">
            <h2 class="fancy-header --margin-0">Step into Diversities of Sound</h2>
            <p class="text-description">{{ intro }}</p>
          </div>
          <a href="#p2" class="icon-arrow-up-wrapper">
            <img class="icon-arrow-up" src="../../assets/icon/chevron-up.svg" />
          </a>
      </div>
      </page-wrapper>

      <page-wrapper id="p2" class="--spacial-wrapper-2" :direction="'column'">
        <div class="gallery-p2-content-wrapper">

          <a href="#" disabled tabindex="-1" class="gallery-p2-series --dummy">
          </a>

          <template v-for="(s, i) in series"> 
            <a :href="'#p'+(i+3)" class="gallery-p2-series --box-shadow-1" :key="i">
              <div class="gallery-p2-header --img-bg" :style="{ 'background-image' : series.length ? 'url(' + s.cover + ')' : 'none' }"></div>
              <div class="gallery-p2-text-wrapper">
                <h2 class="fancy-header gallery-p2-series-name --margin-0">{{ s.name }}</h2>
                <p class="text-description-header gallery-p2-series-name-description">{{ s.subtitle }}</p>
                <p class="text-description gallery-p2-series-name-description">{{ s.intro }}</p>
                <span class="text-description text-description-link">LEARN MORE</span>
              </div>
            </a>
          </template>

          <a href="#" class="gallery-p2-series --dummy">
          </a>
        </div>
        <a href="#p3" class="icon-arrow-up-wrapper --tablet">
          <img class="icon-arrow-up" src="../../assets/icon/chevron-up.svg" />
        </a>
      </page-wrapper>

      <template v-for="(s, i) in series"> 
        <page-wrapper class="--tablet-break" :class="{ '--reverse' : i%2 == 0 }" :key="i" :id="'p'+ (i+3)">
          <GalleryWrapper :series="s" :direction="'column'" />
        </page-wrapper>
      </template>

      <page-wrapper id="galleries-end" class="--tablet-break" :direction="'column'">
        <h2 class="fancy-header --margin-0">Still searching for the perfect match?</h2>
        <a href="/compare" class="btn btn-ghost home-link-btn">Discover More</a>
      </page-wrapper>

      <a v-if="activepage !== 'p1' && activepage !== 'p2' && activepage !== ''" 
        href="#p2" class="icon-to-top-page-wrapper">
        <img class="icon-arrow-up" src="@/assets/icon/chevron-up.svg" />
      </a>
    </div>
  </div>
</template>
  
<script>

import PageWrapper from '@/public/PageWrapper.vue';
import GalleryWrapper from './componets/GalleryWrapper.vue';
// import TextWrapper from '@/public/TextWrapper.vue';
export default {
  name: 'GalleryPage',
  props: {
    activepage: String
  },
  data: () => {
    return {
      fetchUrl: './data/enigma-products.json',
      series: [],
      intro: "Elevate your listening experience with our exclusive audio cable collections, each series in our collection is meticulously crafted to cater to a variety of audio needs, from everyday listening to professional-grade sound production. Discover the perfect cable series tailored to enhance your audio experience",
    }
  },
  created() {
    this.fetchData(this.fetchUrl)
  },
  computed:{ 
    showHelperBtn() {
      console.log(this.activepage)
      return this.activepage !== 'p1'
    },
  },
  methods: {
    fetchData(url) {
      fetch(url)
        .then(response => response.json())
        .then((data) => {
          let series = []
          data.series.forEach((s) => {
            if(s.isShow) {
              let products = []
              s.products.forEach(p => {
                let now = new Date()
                p.promo = p.promo.filter(p => new Date(p.start) <  now && new Date(p.end) > now)
                products.push(p)
              })
              s.products = products
              series.push(s)
            }
          })
          this.series = series.sort((a, b) => { return a.value - b.value})
        })
    },
  },
  watch: {
  },
  components: {
    PageWrapper,
    GalleryWrapper,
  },
}
</script>
  
<style>

.gallery-p1-content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-arrow-up-wrapper {
  width: 100%;
  flex: 0 0 70px;
}

.icon-arrow-up-wrapper.--tablet {
  display: none;
}

.icon-arrow-up {
  width: 70px;
}

.--img-bg {
  width: 100%;
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: 0 70%;
}

.gallery-p1-header {
  width: 100%;
  height: 180px;
  flex: 0 0 32%;
}

.gallery-p1-text-wrapper {
  max-width: 1080px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-p2-content-wrapper {
  display: flex;
  width: 100%;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

.gallery-p2-series {
  text-decoration: none;
  color: #444444;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  margin: 0 5px;
  /* border-radius: 24px; */
}

.gallery-p2-series:hover {
  background-color: #444444;
  color: #ffffff;
}

.gallery-p2-header {
  flex: 0 0 25%;
  /* border-radius: 24px 24px 0 0; */
}

.gallery-p2-text-wrapper {
  padding: 20px;
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  justify-content: space-evenly;
}

.gallery-p2-series-name {
  flex: 0 0 25%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.gallery-p2-series-name-description {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}

.text-description-header.gallery-p2-series-name-description {
  flex: 0 1 auto;
  font-size: 1.3em;
  font-weight: 300;
}

.text-description.gallery-p2-series-name-description {
  font-size: 1.15em;
  font-weight: 200;
}

.gallery-p2-series.--dummy {
  display: none;
  box-shadow: unset;
  pointer-events: none;
  width: 10%;
}

.text-description.text-description-link {
  text-decoration: underline;
  text-underline-offset: 3px;
  font-size: 0.9em;
  margin-bottom: 15px;
}

@media (max-width: 766px) {
  .gallery-p1-content-wrapper .gallery-p1-text-wrapper {
    padding: 0 40px;
  }

  .gallery-p1-content-wrapper .fancy-header { 
    padding-bottom: 10px;
  }

  .gallery-p2-series.--dummy {
    display: flex;
    width: 3%;
  }

  .gallery-p2-text-wrapper {
    padding: 15px 30px;
  }

  .gallery-p2-header {
    flex: 0 0 30%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .gallery-p2-content-wrapper::-webkit-scrollbar {
    display: none;
  }

  .gallery-p2-content-wrapper {
    height: 85%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding: 20px 0;
  }

  .gallery-p2-series {
    position: relative;
    width: 80%;
    scroll-snap-align: center;
    flex: 1 0 auto;
    object-fit: cover;
    display: flex;
  }

  /* .gallery-wrapper .page-content-wrapper {
    padding: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  } */
}

@media (min-width: 767px) and (max-width: 1024px) {
  .icon-arrow-up-wrapper {
    display: none;
  }
  
  .gallery-p1-text-wrapper {
    justify-content: end;
    padding: 0 120px;
  }

  .gallery-container > .gallery-wrapper > .page-wrapper.--spacial-wrapper-1 {
    height: calc(30dvh - 55px);
    display: flex;
  }

  .gallery-container > .gallery-wrapper > .page-wrapper.--spacial-wrapper-2 {
    height: 70dvh;
    max-height: unset;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .gallery-p2-content-wrapper::-webkit-scrollbar {
    display: none;
  }

  .gallery-p2-content-wrapper {
    max-height: 600px;
    height: 80%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding: 20px 0;
  }

  .gallery-p2-series {
    position: relative;
    width: 50%;
    scroll-snap-align: center;
    flex: 1 0 auto;
    object-fit: cover;
    display: flex;
  }

  .gallery-p2-series.--dummy {
    display: flex;
  }

  .gallery-p2-text-wrapper {
    padding: 50px;
  }

  .gallery-p2-header {
    flex: 0 0 35%;
  }

  .gallery-p1-text-wrapper .fancy-header {
    font-size: 36px;
  }

  .icon-arrow-up-wrapper.--tablet {
    display: block;
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 1025px) {
  .gallery-wrapper.view-wrapper {
    width: calc(100% - 200px);
    margin: 0 auto;
  }

  .gallery-wrapper .page-content-wrapper {
    height: calc(100% - 30px);
  }

  .gallery-p2-content-wrapper .fancy-header {
    font-size: 2.7em;
  }

  .gallery-wrapper .page-wrapper.--spacial-wrapper-2 .page-content-wrapper{
    height: calc(100% - 100px);
    margin-top: 50px;
  }
}
  
</style>
  