var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-banner-wrapper --flex-center"},[_vm._l((_vm.promo),function(p,index){return [(p.type === 'text' || p.type === 'discount')?_c('div',{key:index,staticClass:"promo-banner promo-banner-text --flex-center --box-shadow-3",style:({ 'background-color' : p.color,
                'height' : _vm.height ? _vm.height : '46px',
                'width' : _vm.width ? _vm.width : '46px',
                'font-size': _vm.fontSize ? _vm.fontSize : '12px' })},[_vm._v(" "+_vm._s(_vm.getText(p))+" ")]):(p.type === 'icon')?_c('div',{key:index,staticClass:"promo-banner-icon-wrapper --box-shadow-3"},[_c('div',{staticClass:"promo-banner promo-banner-icon",style:({'background-image' : 'url(' + p.src + ')',
                'height' : _vm.height ? _vm.height : '54px',
                'width' : _vm.width ? _vm.width : '54px',
                'font-size': _vm.fontSize ? _vm.fontSize : '14px' })})]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }