<template>
  <div class="radarChart"></div>
</template>

<script>
import * as d3 from "d3"
export default {
  name: 'ComparedChart',
  data: () => {
    return {
      lineLevelColor: '#dddddd',
      bgColor: '#ffffff',
      axisLineColor: '#dddddd',
      axisFontSize: '12px',
      axisLabelColor: '#ffffff',
    }
  },
  props: {
    chartData: Array,
    colors: Array,
    axis: Array,
  },
  computed: {
    getMargin() {
      if (window.innerWidth < 400)
        return {top: 50, right: 50, bottom: 50, left: 50}
      else
        return {top: 80, right: 80, bottom: 80, left: 70}
    },
  },
  methods: {
    renderChart() {
      let width = window.innerWidth < 767 ? 
        Math.min(450, window.innerWidth - 20) - this.getMargin.left - this.getMargin.right :
        Math.min(500, window.innerWidth/2) - this.getMargin.left - this.getMargin.right
      let height = window.innerWidth < 767 ? 
        Math.min(450, window.innerHeight - 100) - this.getMargin.left - this.getMargin.right :
        Math.min(500, window.innerHeight - 220) - this.getMargin.left - this.getMargin.right
      let color = d3.scale.ordinal().range(this.colors)
      let radarChartOptions = {
        w: Math.min(width,height),
        h: Math.min(width,height),
        margin: this.getMargin,
        maxValue: 10,
        levels: 5,
        roundStrokes: true,
        color: color
      }

      try {
        this.RadarChart('.radarChart', this.chartData, radarChartOptions)
      }
      catch(e) {
        console.log(e)
      }
    },
    RadarChart(id, data, options) {
      let cfg = {
       w: 600,				//Width of the circle
       h: 600,				//Height of the circle
       margin: {top: 20, right: 20, bottom: 20, left: 20}, //The margins of the SVG
       levels: 3,				//How many levels or inner circles should there be drawn
       maxValue: 10, 			//What is the value that the biggest circle will represent
       labelFactor: 1.25, 	//How much farther than the radius of the outer circle should the labels be placed
       wrapWidth: 100, 		//The number of pixels after which a label needs to be given a new line
       opacityArea: 0.15, 	//The opacity of the area of the blob
       dotRadius: 2, 			//The size of the colored circles of each blog
       opacityCircles: 0.6, 	//The opacity of the circles of each blob
       strokeWidth: 1, 		//The width of the stroke around each blob
       roundStrokes: false,	//If true the area and stroke will follow a round path (cardinal-closed)
       color: d3.scale.category10()	//Color function
      }
  
      //Put all of the options into a variable called cfg
      if('undefined' !== typeof options){
        for(var i in options){
          if('undefined' !== typeof options[i]){ cfg[i] = options[i]; }
        }//for i
      }//if
  
      //If the supplied maxValue is smaller than the actual one, replace by the max in the data
      let maxValue = 10;
    
      let allAxis = this.axis,	//Names of each axis
        total = allAxis.length,					//The number of different axes
        radius = Math.min(cfg.w/2, cfg.h/2), 	//Radius of the outermost circle
        angleSlice = Math.PI * 2 / total;		//The width in radians of each "slice"
  
      //Scale for the radius
      let rScale = d3.scale.linear()
        .range([0, radius])
        .domain([0, maxValue]);
    
      /////////////////////////////////////////////////////////
      //////////// Create the container SVG and g /////////////
      /////////////////////////////////////////////////////////
    
      //Remove whatever chart with the same id/class was present before
      d3.select(id).select("svg").remove();
      
      //Initiate the radar chart SVG
      var svg = d3.select(id).append("svg")
        .attr("width",  cfg.w + cfg.margin.left + cfg.margin.right)
        .attr("height", cfg.h + cfg.margin.top + cfg.margin.bottom)
        .attr("class", "radar"+id);

      //Append a g element		
      var g = svg.append("g")
          .attr("transform", "translate(" + (cfg.w/2 + cfg.margin.left) + "," + (cfg.h/2 + cfg.margin.top) + ")");
  
      /////////////////////////////////////////////////////////
      ////////// Glow filter for some extra pizzazz ///////////
      /////////////////////////////////////////////////////////

      //Filter for the outside glow
      // var filter = g.append('defs').append('filter').attr('id','glow'),
      //   // eslint-disable-next-line no-unused-vars
      //   feGaussianBlur = filter.append('feGaussianBlur').attr('stdDeviation','2.5').attr('result','coloredBlur'),
      //   feMerge = filter.append('feMerge'),
      //   // eslint-disable-next-line no-unused-vars
      //   feMergeNode_1 = feMerge.append('feMergeNode').attr('in','coloredBlur'),
      //   // eslint-disable-next-line no-unused-vars
      //   feMergeNode_2 = feMerge.append('feMergeNode').attr('in','SourceGraphic');

      /////////////////////////////////////////////////////////
      /////////////// Draw the Circular grid //////////////////
      /////////////////////////////////////////////////////////

      //Wrapper for the grid & axes
      var axisGrid = g.append("g").attr("class", "axisWrapper");

      //Draw the background circles
      axisGrid.selectAll(".levels")
        .data(d3.range(1,(cfg.levels+1)).reverse())
        .enter()
        .append("circle")
        .attr("class", "gridCircle")
        .attr("r", (d) => {return radius/cfg.levels*d})
        .style("fill", this.bgColor)
        .style("stroke", this.lineLevelColor)
        .style("fill-opacity", cfg.opacityCircles)
        // .style("filter" , "url(#glow)");

      /////////////////////////////////////////////////////////
      //////////////////// Draw the axes //////////////////////
      /////////////////////////////////////////////////////////

      //Create the straight lines radiating outward from the center
      var axis = axisGrid.selectAll(".axis")
        .data(allAxis)
        .enter()
        .append("g")
        .attr("class", "axis");
      //Append the lines
      axis.append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", function(d, i){ return rScale(maxValue*1) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("y2", function(d, i){ return rScale(maxValue*1) * Math.sin(angleSlice*i - Math.PI/2); })
        .attr("class", "line")
        .style("stroke", this.axisLineColor)
        .style("stroke-width", "1px")

      //Append the labels at each axis
      axis.append("text")
        .attr("class", "legend")
        .style("font-size", this.axisFontSize)
        .attr("fill", this.axisLabelColor)
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .attr("x", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("y", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice*i - Math.PI/2); })
        .text(function(d){return d})
        .call(this.wrap, cfg.wrapWidth)

      /////////////////////////////////////////////////////////
      ///////////// Draw the radar chart blobs ////////////////
      /////////////////////////////////////////////////////////

      //The radial line function
      var radarLine = d3.svg.line.radial()
        .interpolate("linear-closed")
        .radius(function(d) { return rScale(d.value) })
        .angle(function(d,i) {	return i*angleSlice })

      if(cfg.roundStrokes) {
        radarLine.interpolate("cardinal-closed")
      }

      //Create a wrapper for the blobs	
      var blobWrapper = g.selectAll(".radarWrapper")
        .data(data)
        .enter().append("g")
        .attr("class", "radarWrapper")

      //Append the backgrounds	
      blobWrapper
        .append("path")
        .attr("class", "radarArea")
        .attr("d", (d) => { return radarLine(d) })
        .style("fill", (d,i) => { return cfg.color(i) })
        .style("fill-opacity", cfg.opacityArea);
        // eslint-disable-next-line no-unused-vars
    
      //Create the outlines	
      blobWrapper.append("path")
        .attr("class", "radarStroke")
        .attr("d", (d) => { return radarLine(d) })
        .style("stroke-width", cfg.strokeWidth + "px")
        .style("stroke", (d,i)=> { return cfg.color(i) })
        .style("fill", "none")
        // .style("filter" , "url(#glow)");		

      //Append the circles
      blobWrapper.selectAll(".radarCircle")
        .data((d) => { return d })
        .enter().append("circle")
        .attr("class", "radarCircle")
        .attr("r", cfg.dotRadius)
        .attr("cx", (d,i) => { return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2) })
        .attr("cy", (d,i) => { return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2) })
        .style("fill", (d,i,j) => { return cfg.color(j) })
        .style("fill-opacity", 0.8)
    },
    wrap(text, width) {
      text.each(function() {
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.15, // ems
        y = text.attr("y"),
        x = text.attr("x"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em")
      while (words.length) {
        word = words.pop()
        line.push(word)
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word)
        }
      }
      })
    },
  },
  mounted() {
    this.$nextTick(() => { 
      this.renderChart()
    })
  },
  watch: {
    chartData: {
      handler() {
        this.renderChart()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.legend {
  font-family: 'Raleway', sans-serif;
}

</style>
