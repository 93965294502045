<template>
  <modal name="compared-modal" width="650px" height="90%">
    <div class="modal-header">
      <button class="btn modal-close-btn" @click="handleClose"> X </button>
    </div>
    <div class="modal-container">
      <ComparedAxis v-for="(a, i) in getAxis" :key="i" :name="a" :index="i"
      :productName="productName" :getValue="getValue" :productColor="productColor" />
      <button class="btn close-btn" @click="handleClose">Close</button>
    </div>
  </modal>
</template>

<script>
import ComparedAxis from './ComparedAxis'
export default {
  name: 'ComparedModal',
  props: {
    productName: Array,
    productData: Array,
    productColor: Array,
  },
  computed: {
    getAxis() {
      let axis = []
      if (this.productData[0]) {
        this.productData[0].forEach(e => {
          axis.push(e['axis'])
        })
      }
      
      return axis
    },
  },
  methods: {
    getValue(axis, product) {
      return this.productData[product][axis]
    },
    handleClose() {
      this.$modal.hide('compared-modal')
    }
  },
  components: {
    ComparedAxis,
  },
}
</script>

<style>
.modal-container{
  padding: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 300;
	fill: #444444;
  text-align: center;
  height: 90%;
  overflow: scroll;
}

.modal-header {
  height: 30px;
  margin: 0;
}

.v--modal-box.v--modal {
  max-width: 100dvw !important;
}

.modal-close-btn {
  position: absolute;
  display: block;
  width: 100%;
  text-align: right;
  font-size: 18px;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 10px 30px;
}

.btn.close-btn {
  margin-bottom: 50px;
}

@media (max-width: 650px) {
  .v--modal-box.v--modal {
    left: 0 !important;
  }
}

@media (max-width: 766px) {
  .modal-container {
    padding: 40px 0;
  }
  .btn.close-btn {
    width: 100%;
    padding: 20px 0;
    background-color: #222222;
    bottom: 0;
  }
}
</style>
