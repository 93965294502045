<template>
  <div class="bespoke-container view-container">
    <div class="bespoke-wrapper view-wrapper">
      <div class="bespoke-page page-wrapper">
        <banner-img :imgSrc="bannerSrc" :text="bannerText" />
      </div>
    </div>
  </div>
</template>
  
<script>

import BannerImg from '@/public/BannerImg.vue';
export default {
  name: 'BespokePage',
  data: () => {
    return {
      bannerSrc: "https://scontent.fbkk2-7.fna.fbcdn.net/v/t39.30808-6/399301884_10224832305216202_536075430852491570_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeG0XpHklJA69iuFdUydoM4IT8DhxmJ4Ns1PwOHGYng2zT6HUrkfQryDIrCvNMYM9_cYt8ExM2URsYeF8mNpEhXW&_nc_ohc=mRVrgv11dsAAX_5_opE&_nc_ht=scontent.fbkk2-7.fna&oh=00_AfBYnyOakpLqgz_fIaAKj2z1Cu0-AfniymxPi5aVMT-8JQ&oe=655BBF9B",
      bannerText: "Coming Soon...",
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
  components: {
    BannerImg
  },
}
</script>
  
<style>

@media (max-width: 766px) {
}

@media (min-width: 767px) {
}
  
</style>
  