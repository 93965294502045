<template>
  <div class="gallery-product-materials-wrapper --flex-center">
    <template v-for="(m, index) in materials"> 
      <div class="gallery-product-materials --box-shadow-3" :key="index"
        :style="{ 'border-color' : m.color, 'color' : m.color }">
        {{ m.value }}
        <span class="gallery-product-materials-name --tooltip inverse">{{ m.name }}</span>
      </div>
    </template> 
  </div>
</template>
    
<script>
export default {
  name: 'GalleryMeterial',
  props: {
    materials: Array,
  },
  data: () => {
    return {}
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{
  },
  methods: {
  },
  watch: {
  },
}
</script>
  
<style>
  
.gallery-product-materials-wrapper {
  flex: 0 0 auto;
  flex-direction: row;
}

.gallery-product-materials {
  width: 24px;
  height: 24px;
  font-size: 12px;
  border-radius: 8px;
  margin: 0 1px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: default;
  background-color: rgba(255,255,255,1);
  border: 3px solid;
  font-weight: 600;
}

@media (max-width: 766px) {
}
  
@media (min-width: 767px) and (max-width: 1024px) {
}
  
@media (min-width: 1025px) {
}
    
</style>
    