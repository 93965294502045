<template>
<div class="col product-tag" 
  v-if="isSelected(value)"
  :style="{ backgroundColor: color}" @click="remove(value)">
  <span class="product-tag-lable">{{name}}</span>
  <span class="tag-close" :value="value"> X </span>
</div>
</template>

<script>
export default {
  name: 'ProductTag',
  props: {
    name: String,
    value: String,
    color: String,
    remove: Function,
    isSelected: Function,
  },
}
</script>

<style>
.product-tag {
  border-radius: 2px;
  padding: 0 5px;
  padding-bottom: 0;
  margin: 5px;
  width: fit-content;
  display: inline-block;
  color: #ffffff;
  text-shadow: none;
  cursor: pointer;
}

.product-tag-lable {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  display: inline-block;
  padding: 2px;
  font-size: 13px;
}
</style>
