<template>
  <nav class="navbar" :class="{ 'expand-nav' : isExpanded }"  v-touch:swipe.up="close">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item"><router-link to="/home" class="nav-link">Home</router-link></li>
        <li class="nav-item"><router-link to="/gallery" class="nav-link">Products Series</router-link></li>
        <li class="nav-item"><router-link to="/compare" class="nav-link">Characteristic of Sounds</router-link></li>
        <li class="nav-item"><router-link to="/diy" class="nav-link">Make Your Sound</router-link></li>
        <li class="nav-item"><router-link to="/about" class="nav-link">About Us</router-link></li>
        <li class="nav-item">
          <router-link to="/shop" class="nav-link" style="padding-left: 30px;" 
            @mouseleave.native="handleMouseLeave" @mouseover.native="handleMouseOver">
            <img :src="iconSrc" class="icon shop-icon" /> Shop
          </router-link>
        </li>
      </ul>
      <div class="nav-icon-up-wrapper" @click="close">
        <img class="icon nav-icon-up" :src="'../../assets/icon/arrow-up.svg'">
      </div>
    </div>
  </nav>    
</template>
  
<script>
export default {
  name: 'NavigationBar',
  props: {
    isExpanded: Boolean,
    close: Function,
  },
  data: () => {
    return {
      iconSrc: '../assets/icon/shop.svg',
    }
  },
  
  computed:{ 
  },
  methods: {
    handleMouseOver() {
      this.iconSrc = '../assets/icon/shop-white.svg'
    },
    handleMouseLeave() {
      this.iconSrc = '../assets/icon/shop.svg'
    },
  },
  watch: {
  },

  components: {
  }
}
</script>
  
<style>

nav.navbar {
  display: none;
  background-color: #ffffff;
  color: #444444;
  z-index: 100;
}

.navbar-nav * {
  color: #444444 !important;
  text-decoration: none !important;
  font-size: 14px;
}

.nav-item:has(> a.router-link-active) {
  background-color: #ffffff;
}

.nav-link.router-link-active {
  color: #444444;
  text-decoration: underline !important;
  text-underline-offset: 4px
}

.navbar-nav {
    list-style-type: none;
}

.shop-icon {
  height: 18px;
  position: absolute;
  margin-left: -30px;
  padding-top: 2px;
}

.nav-icon-up-wrapper {
  display: none;
}

@media (max-width: 1024px) {
  nav.navbar {
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    border-bottom: none;
    transition: all 0.5s ease;
  }

  nav.navbar.expand-nav {
    display: flex;
    animation: navslide 0.3s ease;
    flex: 1 1 auto;
  }

  .navbar-collapse {
    display: flex;
    flex-flow: column;
    width: 100%;
    flex: 1 1 auto;
  }

  nav.navbar.expand-nav .nav-icon-up-wrapper {
    display: block;
    position: absolute;
    bottom: -1px;
    width: 100%;
    background-color: #222222;
    padding: 15px 0;
  }

  nav.navbar.expand-nav .nav-icon-up {
    height: 20px;
  }

  .navbar-nav {
    width: 100%;
    height: 100%;
    padding: 10px 0;
  }

  .nav-item {
    padding: 12px 0;
  }

}

@media (min-width: 1025px) {
  nav.navbar{
    height: 45px;
    top: 55px;
    right: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slidedown 0.5s ease;
    border-bottom: 1px solid rgba(223, 223, 223, 0.5);
    -webkit-box-shadow: 0px 10px 10px -8px rgba(223, 223, 223, 0.6), -50px -3px 50px 33px rgba(0, 0, 0, 0); 
    box-shadow: 0px 10px 10px -8px rgba(223, 223, 223, 0.6), -50px -3px 50px 33px rgba(0, 0, 0, 0);
  }

  .navbar-collapse#navbarNav {
    width: 100%;
    display: block;
    max-width: 1920px;
  }
  .navbar-nav {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
  }
  .nav-item {
    display: flex;
    padding: 14px 30px;
  }

  .nav-item:has(> a.router-link-active) {
    padding: 11px 30px;
  }

  a.router-link-active {
    pointer-events: none;
  }

  .nav-item:has(> a.nav-link:hover) {
    background-color: #444444;
    color: #ffffff;
    transition: all 0.5s ease;
  }

  a.nav-link:hover{
    color: #ffffff !important;
  }

  a.nav-link:hover .shop-icon {
    fill: #ffffff;
  }

}

</style>
  