<template>
  <div class="homepage-container view-container --full-width">
    <div class="homepage-wrapper view-wrapper">
      
      <page-wrapper id="p1" :imgBg="imgBg01" class="homepage-content-p1">
        <text-wrapper class="homepage-text-wrapper --gradient-bg">
          <p class="text-subtitle --margin-bottom">Step beyond the ordinary.</p>
          <p class="text-subtitle">We're not just changing how you listen, we're changing what you hear.</p>
          <h2 class="fancy-header --font-weight-100">Redefine Your Acoustic Reality</h2>
          <a href="/shop" class="btn btn-ghost invert home-link-btn">Shop Now</a>
        </text-wrapper>
      </page-wrapper>

      <page-wrapper id="p2" :direction="'row'" class="home-content-p2">
        <div class="home-content-p2-half --img-bg"
         :style="{'background-image' : 'url(' + imgBg02 + ')' }"></div>
         <div class="home-content-p2-half home-content-2-lable">
          <text-wrapper :color="'#444444'" :bgColor="'transparent'">
            <p class="text-description --font-weight-100">{{ description1 }}</p>
            <p class="text-description-header --font-weight-100 --margin-top-0">{{ '- Enigma Audio Founder-' }}</p>
          </text-wrapper>
        </div>
      </page-wrapper>

      <page-wrapper id="p3" class="--black-bg home-content-p3" :direction="'column'">
        <text-wrapper class="home-content-p3-text-wrapper">
          <h2 class="fancy-header --color-white --margin-0">Explore Our Diverse Range of Audio Cable Series</h2>
          <p class="text-description --color-white --font-weight-100">{{ description2 }}</p>
          <a href="/gallery" class="btn btn-ghost invert home-link-btn">Discover More</a>
        </text-wrapper>
      </page-wrapper>

      <page-wrapper id="p4" :direction="'row'" class="home-content-p2">
        <div class="home-content-p2-half home-content-2-lable">
          <text-wrapper class="--compare" :color="'#444444'" :bgColor="'transparent'">
            <h2 class="fancy-header --margin-0">Compare Our Sound Specialties</h2>
            <p class="text-description --font-weight-100">{{ description3 }}</p>
            <a href="/compare" class="btn btn-ghost home-link-btn home-find-more-btn">Compare Now</a>
          </text-wrapper>
        </div>
        <div class="home-content-p2-half --img-bg"
         :style="{'background-image' : 'url(' + imgBg03 + ')' }"></div>
      </page-wrapper>

      <!-- <page-wrapper class="--dark-bg" :direction="'column'">
        <h2 class="fancy-header --color-white --margin-0">Explore Our Sound Specialty</h2>
        <a href="/compare" class="btn btn-ghost invert home-link-btn">FIND MORE</a>
      </page-wrapper>

      <page-wrapper :direction="'column'">
        <h2 class="fancy-header  --margin-0">Craft Your Sound Signature</h2>
        <a href="/diy" class="btn btn-ghost home-link-btn">Find More...</a>
      </page-wrapper> -->

    </div>
  </div>
</template>
  
<script>

import PageWrapper from '@/public/PageWrapper.vue';
import TextWrapper from '@/public/TextWrapper.vue';
export default {
  name: 'HomePage',
  data: () => {
    return {
      imgBg01: "'https://shop.enigma-audio.com/images/8D1A2000.jpg?fbclid=IwAR1vyUCZzXESQNOBeTIJg0oKRuf63OE6nQHOH8k-1OvEu5SanYCSQt62_tA'",
      imgBg02: "https://shop.enigma-audio.com/images/8D1A2049.jpg?fbclid=IwAR1IeLAaCzv2jFx1KFf6PUU74n3GK0gIEC5xZZJceKrnjT_KPO73cyEEChw",
      imgBg03: "https://shop.enigma-audio.com/images/8D1A1997copy2.jpg?fbclid=IwAR2kk8uC_3nH-02fkEAWqHD52tesZzno91iotXRrWXO1UoBDvmgq7M8ntE8",
      description1: `"Experience the true essence of your music with our cables, engineered for precision. Our commitment is to preserve the integrity of the artist's vision and the sound engineer's meticulous design. With us, every note is a testament to authenticity and passion in sound delivery. Join us in our ambition to bring pure, unaltered musical brilliance right to your ears."`,
      description2: 'Discover our premium audio cable series, where each collection is crafted to transform your listening experience. From versatile everyday use to professional-grade precision and luxurious auditory indulgence, we offer the perfect sonic companion for every listener. Dive in and find your perfect audio match.',
      description3: 'Elevate your experience and see how they compare on our product comparison page, where exceptional sound meets your exact needs.',
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
  components: {
    PageWrapper,
    TextWrapper
  },
}
</script>
  
<style>

.home-content-p2-half{
  width: 50% !important;
  height: 100%;
}

.btn.home-link-btn {
  width: 280px;
  margin-top: 30px;
  font-size: 19px;
  text-decoration: none;
  padding: 12px 0;
}

.home-content-2-lable .text-description {
  letter-spacing: 0.01em;
}

.home-content-2-lable {
  align-items: center;
}

.home-content-p3-text-wrapper {
  max-width: 1200px;
  padding: 0 50px;
  width: calc(100% - 100px);
  align-items: center;
}

@media (max-width: 766px) {
  .home-content-p2-half {
    position: absolute;
    height: 100%;
    width: 100% !important;
  }

  .home-content-2-lable .text-wrapper {
    padding: 50px;
    width: calc(100% - 100px);
  }

  .home-content-p2-half.home-content-2-lable {
    z-index: 1;
  }
  .home-content-p2-half.home-content-2-lable > .text-wrapper {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: #ffffff !important;
  }

  .text-wrapper {
    width: calc(100% - 60px);
    padding: 0 30px;
  }

  .homepage-text-wrapper .fancy-header {
    margin: 0;
    margin-top: 24px;
    font-size: 28px;
  }

  .homepage-text-wrapper .text-subtitle {
    margin: 0;
    font-size: 14px;
  }

  .homepage-text-wrapper .btn.home-link-btn {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homepage-content-p1 .homepage-text-wrapper {
    justify-content: flex-end;
  }

  .homepage-content-p1 .homepage-text-wrapper .btn.home-link-btn {
    margin-bottom: 50px;
  }

  .home-find-more-btn {
    color: #ffffff;
    border-color: #ffffff;
    align-self: center;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .homepage-text-wrapper {
    text-align: right;
    padding-right: 80px;
    align-items: end;
  }

  .homepage-text-wrapper .btn.home-link-btn {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homepage-text-wrapper .text-subtitle {
    margin: 0;
    align-self: flex-end;
    font-size: 16px;
    max-width: 70%;
  }

  .homepage-text-wrapper .fancy-header {
    margin: 0;
    margin-top: 2rem;
  }

  .home-content-2-lable .text-wrapper {
    width: calc(100% - 100px);
    padding: 0 50px;
  }

  .home-content-2-lable .--compare {
  align-items: flex-start;
  text-align: left;
}

  .home-content-2-lable .--compare  .btn {
    text-align: center;
  }
}

@media (min-width: 1025px) {
  .homepage-text-wrapper .text-subtitle.--margin-bottom {
    margin-bottom: 20px;
  }

  .homepage-text-wrapper {
    text-align: right;
    padding-right: 120px;
    align-items: end;
  }

  .homepage-text-wrapper .text-subtitle {
    align-self: flex-end;
    margin: 0;
    max-width: 55%;
    font-weight: 200;
  }

  .homepage-text-wrapper .fancy-header {
    margin: 0;
    margin-top: 3rem;
  }

  .homepage-text-wrapper .btn.home-link-btn {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-content-2-lable {
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .home-content-2-lable .text-wrapper {
    max-width: 450px;
  }
  .home-content-2-lable .text-wrapper {
    width: calc(100% - 100px);
    padding: 0 50px;
  }
  .home-content-2-lable .--compare {
  align-items: flex-start;
  text-align: left;
  }

  .home-content-2-lable .--compare  .btn {
    text-align: center;
  }
}
  
</style>
  