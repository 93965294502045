<template>
  <div class="banner-img-wrapper">
    <img class="banner-img" :src="imgSrc" />
    <div v-if="text" class="banner-text-wrapper"
      :style="{ 'background-color': textBackgroundColor ? textBackgroundColor : '#222222',
                'opacity': textBackgroundOpacity ? textBackgroundOpacity : 0.8 }">
      <h2 class="banner-text"
        :style="{ 'color': textColor ? textColor : 'white' }"> {{ text }} </h2>
      <p  v-if="subtitle" class="banner-subtitle"
        :style="{ 'color': subtitleColor ? subtitleColor : 'white' }"> {{ subtitle }} </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'BannerImg',
  props: {
    imgSrc: String,
    text: String,
    textColor: String,
    textBackgroundColor: String,
    textBackgroundOpacity: Number,
    subtitle: String,
    subtitleColor: String,
  },
  data: () => {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
}
</script>
  
<style>

.banner-img-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  -webkit-animation: fadein 0.5s ease; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s ease; /* Firefox < 16 */
        -ms-animation: fadein 0.5s ease; /* Internet Explorer */
         -o-animation: fadein 0.5s ease; /* Opera < 12.1 */
            animation: fadein 0.5s ease;
}

.banner-img {
  width: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.banner-text-wrapper {
  position: absolute;
  width: 100%;
  height: 65%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.banner-text {
  font-weight: 400;
  font-size: 46px;
  padding: 0;
}

.banner-text, .banner-subtitle {
  width: 100%;
}

.banner-subtitle {
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
}

@media (min-width: 767px) {
}
  
</style>
  