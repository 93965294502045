<template>
  <div class="product-price" :class="{ 'has-discount': hasDiscount }" :style="fontSizeStyle">
    <p>from</p>
    <p class="price-tag">{{ formattedPrice }}</p>
    <p class="discount-price" v-if="hasDiscount">{{ formattedDiscountPrice }}</p>
  </div>
</template>

<script>
export default {
  name: 'ProductPrice',
  props: {
    price: {
      type: Number,
      default: 0,
    },
    promo: {
      type: Array,
      default: () => [],
    },
    fontSize: String,
  },
  computed: {
    fontSizeStyle() {
      return this.fontSize ? { 'font-size': this.fontSize } : {}
    },
    formattedPrice() {
      return this.price ? this.formatPrice(this.price) : 'Please contact us'
    },
    hasDiscount() {
      return !!this.currentDiscountPromo
    },
    formattedDiscountPrice() {
      if (!this.currentDiscountPromo) return 'N/A'
      const discountValue = this.currentDiscountPromo.value
      const discountedPrice = this.currentDiscountPromo.unit === '%' ?
                              this.price * (1 - discountValue / 100) :
                              this.price - discountValue
      return this.formatPrice(discountedPrice);
    },
    currentDiscountPromo() {
      return this.promo.find(p => p.type === 'discount')
    },
  },
  methods: {
    formatPrice(price) {
      return `฿${price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },
  },
}
</script>

<style>
.product-price {
  letter-spacing: 0.05em;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.price-tag {
  position: relative;
  margin-left: 8px;
}

.has-discount .price-tag::before {
  top: 50%;
  background: #F90716;
  opacity: 0.7;
  content: '';
  width: 110%;
  position: absolute;
  height: 0.1em;
  border-radius: 0.1em;
  left: -5%;
  white-space: nowrap;
  display: block;
  transform: rotate(-15deg);  
}

.discount-price {
  margin-left: 10px;
  color: #F90716;
  font-weight: 700;
}

/* Responsive styling for different screen sizes */
@media (max-width: 766px) {
  .product-price {
    font-size: 10px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .product-price {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  .product-price { 
    font-size: 14px;
  }
}
</style>
