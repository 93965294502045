<template>
  <div class="gallery-description-container" @click="toggle">
    <div class="gallery-description-toggle-btn">x</div>
    <div class="gallery-description-header --flex-center">
      <h5 class="fancy-header gallery-product-name">{{ product.name }}</h5>
      <div class="gallery-product-pros-wrapper">
        <GalleryMeterial v-if="product.materials" :materials="product.materials" />
        <GalleryProperties v-if="product.properties" :properties="product.properties" />
      </div>
    </div>

    <div class="gallery-description-body">
      <GalleryAtt :att="product.att" /> 

      <div class="gallery-product-btn-group --flex-center">

        <div class="gallery-product-pros-wrapper --tablet">
          <GalleryMeterial v-if="product.materials" :materials="product.materials" />
          <GalleryProperties v-if="product.properties" :properties="product.properties" />
        </div>

        <div class="gallery-product-btn-wrapper --flex-center">
          <a v-if="product.id" :href="getCompareLink" class="gallery-product-compare-link">
            Sound Characteristic
          </a>
        </div>

        <div class="gallery-product-btn-wrapper buy-btn-group --flex-center">
          <a class="btn btn-ghost invert gallery-product-btn" :href="getShop">
            Buy Now
          </a>
          <product-price :price="product.price" :promo="product.promo"></product-price>
        </div>

      </div>

    </div>

    <p class="text-link gallery-product-link">MORE DETAILS</p>
  </div>
</template>
  
<script>

import GalleryMeterial from './GalleryMeterial.vue'
import GalleryProperties from './GalleryProperties.vue'
import GalleryAtt from './GalleryAtt.vue'
import ProductPrice from '@/public/ProductPrice.vue'
export default {
  name: 'GalleryDescription',
  props: {
    product: Object,
    toggle: Function,
  },
  data: () => {
    return { 
      discontPrice: 0,
    }
  },
  mounted() {
    if(this.product.price) {
      this.updatePrice(this.product)
    }
  },
  computed:{
    getPrice() {
      if(this.product.price) {
        return this.priceToText(this.product.price)
      } else {
        return 'Please contact us'
      }
    },
    getDiscountPrice() {
      return this.priceToText(this.discontPrice)
    },
    getShop() {
      return this.product.shop ? this.product.shop : 'https://shop.enigma-audio.com/'
    },
    getCompareLink() {
      return '/compare?product=' + this.product.id
    },
  },
  methods: {
    priceToText(p) {
      return '฿' + p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    updatePrice(product) {
      if(product.price) {
        let promo = product.promo.filter(p => p.type === 'discount')
        if(promo.length) {
          if(promo[0].unit === '%')
            this.discontPrice = product.price*((100 - promo[0].value)/100)
          else
            this.discontPrice = product.price - promo[0].value
        } else
        this.discontPrice = 0
      }
    }
  },
  watch: {
    product(val) {
      this.updatePrice(val)
    }
  },
  components: {
    GalleryMeterial,
    GalleryAtt,
    GalleryProperties,
    ProductPrice
  },
}
</script>
  
<style>

.gallery-description-toggle-btn {
  display: none;
  position: absolute;
  top: 15px;
  right: 25px;
  color: #ffffff;
  font-size: 28px;
}

.gallery-product-compare-link {
  color: #ffffff;
}

.--border {
  border-bottom: 1px solid;
  flex: 0 0 1px !important;
  width: 50%;
  margin-bottom: -1px;
}

.gallery-description-header {
  flex: 1 1 auto;
  flex-direction: column;
}

.gallery-product-pros-wrapper {
  padding: 0 20px;
  display: flex;
  flex: 1 1 auto;
}

.gallery-product-pros-wrapper.--tablet {
  display: none;
}

.fancy-header.gallery-product-name {
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.gallery-description-body {
  flex: 1 1 auto;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.gallery-product-btn-group {
  flex: 1 1 auto;
  flex-direction: column;
}

.gallery-product-btn-wrapper {
  flex: 1 1 auto;
  flex-direction: column;
}

.text-link.gallery-product-link {
  display: none;
}

.gallery-description-container {
  display: flex;
  flex-flow: column;
  background-color: #444444;
  color: #ffffff;
  height: 100%;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.--dark-bg .gallery-description-container {
  background-color: #ffffff;
  color: #444444;
}

.--reverse .gallery-description-container {
  background-color: #222222;
}

.gallery-product-btn {
  text-decoration: none;
}

@media (max-width: 766px) {
  .gallery-description-container {
    width: 100%;
    height: 65%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    background-color: #222222;
    transition: all 0.5s ease;
  }

  .text-link.gallery-product-link { 
    display: block;
    flex: 1 1 auto;
    font-size: 11px;
    margin-top: 12px;
  }

  .--show-product .gallery-description-container {
    transition: all 0.5s ease;
    flex: 1 1 auto;
    position: absolute;
    height: calc(75% - 20px);
  }

  .gallery-description-body, .gallery-product-details, .gallery-product-pros-wrapper {
    display: none;
  }

  .--show-product .gallery-description-body, .--show-product .gallery-product-details,
  .--show-product .gallery-product-pros-wrapper, .--show-product .gallery-description-toggle-btn {
    display: flex;
  }

  .gallery-product-pros-wrapper.--tablet {
    display: none !important;
  }

  .fancy-header.gallery-product-name {
    font-size: 20px;
  }

  .--show-product .fancy-header.gallery-product-name {
    font-size: 28px;
  }

  .--show-product .text-link.gallery-product-link { 
    display: none;
  }

  .--show-product .product-price {
    font-size: 14px;
    margin-top: 15px;
  }

  .gallery-product-compare-link {
    font-size:  16px;
  }

  .gallery-product-btn {
    margin-top: 15px;
    margin-bottom: -20px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .gallery-description-header {
    flex: 1 1 auto;
    border-bottom: 1px solid;
    width: 50%;
  }

  .gallery-description-body {
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 60px);
    max-width: 800px;
    padding: 0px 30px 20px;
  }

  .gallery-description-body > div{
    flex: 0 0 50%;
    height: 100%;
  }

  .gallery-product-btn-group > * {
    flex: 0 0 auto;
    margin-bottom: 20px;
  }

  .gallery-product-btn-group .buy-btn-group {
    margin-bottom: 0;
  }

  .gallery-product-compare-link {
    font-size:  16px;
  }

  .gallery-product-pros-wrapper {
    display: none;
  }

  .gallery-product-pros-wrapper.--tablet {
    display: flex;
  }

  .fancy-header.gallery-product-name {
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .gallery-description-container {
    flex: 0 0 35%;
  }

  .gallery-product-compare-link {
    font-size:  14px;
  }
}
  
</style>
  