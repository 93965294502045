<template>
  <div class="body" ref="body"  @scroll.passive="handleImgScroll">
    <header :class="{ 'toggle-header' : isNavbarExpaned, '--show-nav-promo-banner' : promoBanner }" >
      <div class="nav-promo-banner" v-if="promoBanner">
        <a :href="promoBannerLink" class="nav-promo-banner-link">
            {{ promoBanner }} 
        </a>
      </div>
      <div class="header-wrapper --box-shadow-bottom">
        <div class="icon-wrapper" @click="toggleNavBar">
          <img class="nav-icon" :src="isNavbarExpaned ? './assets/icon/close.svg' : './assets/icon/menu.svg'" 
           :class="{ '--spin': spin }">
        </div>
        <a href="/">
          <img class="web-logo" src="https://shop.enigma-audio.com/wp-content/uploads/2023/06/enigmalogoheader2.png" />
        </a>
      </div>
      <navigation-bar :isExpanded="isNavbarExpaned" :close="closeNav" />
    </header>

    <div class="container" ref="container">

      <router-view class="view" :activepage="activepage" @hook:mounted="onPageMount()"></router-view>
      <footer class="page-wrapper" v-if="$route.path !== '/compare'">
        <p>© (2020) Cross Lambda Web Solution</p>
      </footer>

    </div>
  </div>
</template>
  
<script>

import NavigationBar from './public/NavigationBar.vue'
export default {
  props: {
  },
  name: 'App',
  data: () => {
    return {
      promoBanner: 'Winter Special Offers Till Dec 25th: Enigma Dimension NG from ฿2,590 (Was ฿2,990)! Plus, 10% Off Storewide & 0% Installment Plan.',
      promoBannerLink: 'https://shop.enigma-audio.com/product/enigma-dimension-ng/',
      fetchUrl: './data/enigma-products.json',
      activepage: 'p1',
      isNavbarExpaned: false,
      spin: false,
    }
  },
  created() {
    this.fetchData(this.fetchUrl)

    this.$nextTick(() => { 
      if (!this.isCollapseNav) {
        setTimeout(() => { this.isNavbarExpaned = true }, 300)
      }
    })
  },
  methods: {
    fetchData(url) {
      fetch(url)
        .then(response => response.json())
        .then((data) => {
          if(data.banner) {
            this.promoBanner = data.banner.text
            this.promoBannerLink = data.banner.link
          }
        })
    },
    toggleNavBar(e) {
      if(!this.isNavbarExpaned)
        this.spinning()
      this.isNavbarExpaned = !this.isNavbarExpaned
      e.preventDefault()
      e.stopPropagation()
    },
    spinning() {
      setTimeout(() => {
        this.spin = true
      }, 10)
      setTimeout(() => {
        this.spin = false
      }, 200)
    },
    closeNav() {
      this.isNavbarExpaned = false
    },
    expandNavbar() {
      this.isNavbarExpaned = true
    },
    handleImgScroll(e) {
      let scrollTop = e.target.scrollTop
      let pages = e.target.querySelector("div.view-wrapper").children
      let activepage

      for (var i = 0; i < pages.length; i++) {
        if (Math.abs(pages[i].offsetTop - scrollTop) < 200) {
          activepage = pages[i]
        }
      }
      if (activepage)
        this.activepage = activepage.id
    },
    onPageMount() {
      setTimeout(() => { this.$refs.body.scroll({ top: 0, left: 0, behavior: "instant" }) }, 100)
    }
  }, 
  computed:{ 
    isCollapseNav() {
      return window.innerWidth < 1025
    },
  },
  watch:{
   '$route.path' (){
      if (this.isCollapseNav)
        this.isNavbarExpaned = false
    },
  },

  components: {
    NavigationBar,
  }
}
</script>
  
<style>

@import './assets/css/animations.css';
@import './assets/css/button.css';
@import './assets/css/public.css';
@import './assets/css/font.css';
@import './assets/css/helper.css';

.icon-wrapper {
  position: absolute;
  width: 80px;
  padding: 7px 0;
  right: 0;

}

.icon-wrapper:hover {
  opacity: 0.85;
  cursor: pointer;
}

.nav-icon {
  float: right;
  height: 28px;
}

.logo {
  width: 300px;
}

.nav-outside-area {
  position: fixed;
  width: calc(100dvw + 10px);
  height: 100dvh;
  left: 0;
  top: 0;
  background-color: #222222;
  opacity: 0.6;
  z-index: 10;
  pointer-events: none;
}

@media (max-width: 766px) {
  .icon-wrapper {
    padding-right: 20px;
  }

  header {
    z-index: 200;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .icon-wrapper {
    padding-right: 40px;
  }
}
@media (min-width: 1025px) {
  .icon-wrapper {
    display: none;
  }
  .nav-outside-area {
    display: none !important;
  }
}
  
</style>
  