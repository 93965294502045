<template>
  <div class="about-us-container view-container">
    <div class="about-us-wrapper view-wrapper">

      <page-wrapper :imgBg="imgBg01">
        <text-wrapper :height="textWrapperHeight">
          <h2 class="fancy-header --margin-0">Our Stores</h2>
        </text-wrapper>
      </page-wrapper>
      
    </div>
  </div>
</template>
  
<script>

import PageWrapper from '@/public/PageWrapper.vue';
import TextWrapper from '@/public/TextWrapper.vue';
export default {
  name: 'DIYPage',
  data: () => {
    return {
      imgBg01: "'https://shop.enigma-audio.com/images/8D1A2043.jpg?fbclid=IwAR1mKCsCLSdzW_50DE1P_2fqKBiHFcNsOoP8tfsZ6DuAESu5CdrFn17tSMU'",
      textWrapperHeight: '60%',
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
  components: {
    PageWrapper,
    TextWrapper
},
}
</script>
  
<style>

@media (max-width: 766px) {
}

@media (min-width: 767px) {
}

</style>
  