<template>
  <div class="img-slides-container" v-if="images">
    <div class="img-slides-wrapper" ref="slideWrapper" @scroll.passive="handleImgScroll">
      <template v-for="(img, index) in images">
        <!-- <img class="img-slides-component" :src="img.src" :id="img.id" :key="index" /> -->
        <div class="img-slides-img --img-bg" :id="img.id" :key="index" 
          :style="{ 'background-image' : img.src ? 'url(' + img.src + ')' : 'none' }">
          <slot></slot>
        </div>
      </template>
    </div>
    <nav class="img-slides-navigation" v-if="isNav">
      <div class="img-slides-navigation-control-wrapper">
        <img v-if="isShowLeftNav" class="img-slides-navigation-control left" 
          src="../assets/icon/chevron-left-circle.svg" @click="handleLeftNav" />
        <img v-if="isShowRightNav" class="img-slides-navigation-control right" 
          src="../assets/icon/chevron-right-circle.svg" @click="handleRightNav" />
      </div>
      <div class="img-slides-navigation-link-wrapper">
        <template v-for="(img, index) in images" > 
          <a class="img-slides-navigation-link"
            :class="{ 'active': img.id === activeId }" 
            :href="'#'+img.id" @click="handleClick" :key="index">
            Jump to {{ img.id }}</a>
        </template>
      </div>
    </nav>
  </div>
</template>
  
<script>
export default {
  name: 'ImgSlides',
  props: {
    setActiveImg: Function,
    images: Array,
    isNav: Boolean,
  },
  data: () => {
    return {
      lastscroll: null,
      activeId: '1',
      activeIndex: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  computed:{ 
    isShowLeftNav() {
      return this.activeIndex === 0 ? false : true
    },
    isShowRightNav() {
      return this.activeIndex === this.images.length-1 ? false : true
    },
  },
  methods: {
    handleImgScroll(e) {
      let scrollLeft = e.target.scrollLeft
      let imgs = e.target.children
      let activeimg, activeindex
      for (var i = 0; i < imgs.length; i++) {
        if (Math.abs(imgs[i].offsetLeft - scrollLeft) < 200) {
          activeimg = imgs[i]
          activeindex = i
        }
      }
      if (activeimg && this.activeId !== activeimg.id) {
        this.activeId = activeimg.id
        this.activeIndex = activeindex
      }
    },
    handleClick(e) {
      let link = e.target
      this.toSlide(link.getAttribute("href").replace("#", ""))
      e.preventDefault()
    },
    handleLeftNav(e) {
      this.toSlide(this.images[this.activeIndex - 1].id)
      e.preventDefault()
    },
    handleRightNav(e) {
      this.toSlide(this.images[this.activeIndex + 1].id)
      e.preventDefault()
    },
    toSlide(id) {
      let matchingImg = this.$refs.slideWrapper.querySelector("*[id='" + id + "']")
      this.$refs.slideWrapper.scrollTo({ left: matchingImg.offsetLeft, behavior: "smooth" })
    },
  },
  watch: {
    activeId(newVal) {
      this.setActiveImg(newVal)
    },
  },
}
</script>
  
<style>

.img-slides-container {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.img-slides-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  flex-flow: row;
  align-items: center;
  justify-content: start;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.img-slides-wrapper::-webkit-scrollbar {
  display: none;
}

.img-slides-img {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 5px;
  position: relative;
  width: 80%;
  scroll-snap-align: start;
}

.img-slides-img.--img-bg {
  background-position: center;
}

.img-slides-img.--dummy {
  display: flex;
  box-shadow: unset;
  pointer-events: none;
  width: 20%;
}

.img-slides-component {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  margin: 0 5px;
  position: relative;
  width: 80%;
  scroll-snap-align: start;
  object-fit: cover;
}

.img-slides-wrapper, nav.img-slides-navigation {
  width: 100%;
}

nav.img-slides-navigation { 
  position: relative;
  margin-top: -50px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

nav.img-slides-navigation > .img-slides-navigation-link-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.img-slides-navigation-link-wrapper > a.img-slides-navigation-link {
  width: 10px;
  height: 10px;
  max-width: 10px;
  margin: 0 5px;
  display: inline-block;
  overflow: hidden;
  text-indent: -91024px;
  border-radius: 100%;
  background: #999999;
  z-index: 1;
}

.img-slides-navigation-link-wrapper > a.img-slides-navigation-link:hover,
.img-slides-navigation-link-wrapper > a.img-slides-navigation-link:focus {
  background: #cccccc;
}

.img-slides-navigation-link-wrapper > a.img-slides-navigation-link.active {
  background: #ffffff;
}

.img-slides-navigation-product-name {
  display: none;
}

.img-slides-navigation-control-wrapper {
  position: absolute;
  height: 40px;
  width: 100%;
  opacity: 0.6;
}

.img-slides-navigation-control {
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1;
}

.img-slides-navigation-control.left {
  float: left;
  margin-left: 15px;
}

.img-slides-navigation-control.right{
  float: right;
  margin-right: 15px;
}

@media (max-width: 766px) {
  nav.img-slides-navigation { 
    justify-content: space-evenly;
    height: 25px;
    margin-top: -25px;
  }

  .img-slides-navigation-control {
  height: 20px;
  width: 20px;
  display: none;
  }

  .img-slides-navigation-control-wrapper {
    height: 20px;
  }


  .img-slides-navigation-link-wrapper > a.img-slides-navigation-link {
    width: 7px;
    height: 7px;
    max-width: 7px;
    margin: 0 4px;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
}

</style>
  