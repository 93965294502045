<template>
  <div class="gallery-product-details">
    <div class="gallery-product-details --border"></div>

    <div class="gallery-product-details-list">
      <template v-for="(a, index) in att"> 
        <div class="gallery-product-att" :key="index">
          <p class="gallery-product-att-name">{{ a.name }}: {{ a.value }}</p>
        </div>
      </template> 
    </div>
  </div>
</template>
      
<script>
export default {
  name: 'GalleryAtt',
  props: {
    att: Array,
  },
  data: () => {
    return {}
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{
  },
  methods: {
  },
  watch: {
  },
}
</script>
  
<style>

.gallery-product-details {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
  
.gallery-product-details-list {
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 20px;
  font-weight: 400;
}

.gallery-product-att {
  list-style-type: none;
  font-size: 1.1em;
  display: flex;
  flex: 0 0 auto;
  padding: 1px;
}

.gallery-product-att p { 
  margin: 0;
  padding: 0 2px;
  flex: 1 1 auto;
}

@media (max-width: 766px) {
}
  
@media (min-width: 767px) and (max-width: 1024px) {
  .gallery-product-details.--border {
    display: none;
  }

  .gallery-product-att {
    font-size: 1.4em;
    padding: 4px;
  }
}
  
@media (min-width: 1025px) {
  .gallery-product-details-list {
    max-width: 600px;
  }
}
    
</style>
