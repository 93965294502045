<template>
  <div class="page-wrapper"
    :style="{ 'background-image' : imgBg ? 'url(' + imgBg + ')' : 'none', 
              'background-color' : bgColor ? bgColor : 'transparent',
              'color': color ? color : '#444444' }">
    <div class="page-content-wrapper"
      :style="{ 'flex-direction' : direction ? direction : 'row'}" >
      <slot></slot>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'PageWrapper',
  props: {
    imgBg: String,
    color: String,
    bgColor: String,
    direction: String,
  },
  data: () => {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
}
</script>
  
<style>

.page-wrapper {
  position: relative;
  overflow-y: hidden;
  scroll-snap-align: end;
  scroll-snap-stop: normal;
  background-size: cover;
  background-position: center;
}

.page-wrapper > .page-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 766px) {
  .page-wrapper {
    height: calc(100dvh - 110px);
    max-height: 926px;
    scroll-margin-top: 110px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .page-wrapper {
    height: calc((100dvh - 95px) / 2);
    max-height: 590px;
    scroll-margin-top: 95px;
  }

  .page-wrapper.--tablet-break {
    height: calc(100dvh - 95px);
    max-height: none;
    scroll-margin-top: 95px;
  }
}

@media (min-width: 1025px) {
  .page-wrapper {
    height: calc(100dvh - 140px);
    max-height: 1080px;
  }

  @media (min-height: 1080px) {
    .page-wrapper {
      height: calc((100dvh - 100px)/2);
      max-height: 1080px;
    }
  }
}
  
</style>
  