import Vue from 'vue'
import App from './App.vue'

import HomePage from './pages/home/HomePage.vue'
import ProductCompare from './pages/compare/ProductCompare.vue'
import DIYPage from './pages/diy/DIYPage.vue'
import BespokePage from './pages/bespoke/BespokePage.vue'
import GalleryPage from './pages/gallery/GalleryPage.vue'
import AboutUsPage from './pages/about/AboutUsPage.vue'

import VModal from 'vue-js-modal'
import VueRouter from 'vue-router'
import Vue2TouchEvents from 'vue2-touch-events'
import '../node_modules/vue-js-modal/dist/styles.css'

Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(VueRouter)
Vue.use(Vue2TouchEvents)

const routes = [
  { path: '/home', component: HomePage, name: 'home' },
  { path: '/compare', component: ProductCompare, name: 'compare' },
  { path: '/diy', component: DIYPage, name: 'diy' },
  { path: '/bespoke', component: BespokePage, name: 'bespoke' },
  { path: '/gallery', component: GalleryPage, name: 'gallery' },
  { path: '/about', component: AboutUsPage, name: 'about' },
  {
    path: '/shop',
    beforeEnter() {location.href = 'https://shop.enigma-audio.com/'}
  },
  { path: '*', redirect: '/home' }
]

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
