<template>
  <div class="promo-banner-wrapper --flex-center">
    <template v-for="(p, index) in promo"> 
      <div v-if="p.type === 'text' || p.type === 'discount'" class="promo-banner promo-banner-text --flex-center --box-shadow-3" :key="index"
        :style="{ 'background-color' : p.color,
                  'height' : height ? height : '46px',
                  'width' : width ? width : '46px',
                  'font-size': fontSize ? fontSize : '12px' }">
        {{ getText(p) }}
      </div>
      <div v-else-if="p.type === 'icon'" class="promo-banner-icon-wrapper --box-shadow-3" :key="index">
        <div class="promo-banner promo-banner-icon"
        :style="{'background-image' : 'url(' + p.src + ')',
                  'height' : height ? height : '54px',
                  'width' : width ? width : '54px',
                  'font-size': fontSize ? fontSize : '14px' }">
        </div>
      </div>
    </template> 
  </div>
</template>
    
<script>
export default {
  name: 'PromoBanner',
  props: {
    promo: Array,
    height: String,
    width: String,
    fontSize: String,
  },
  data: () => {
    return {}
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{
  },
  methods: {
    getText(promo) {
      if (promo.type === 'text') 
        return promo.value
      else (promo.type === 'discount') 
        if (promo.unit === '%')
          return '-' + promo.value + '%'
        else 
          return promo.text ? promo.text : '-' + promo.value + '.-'
    },
  },
  watch: {
  },
}
</script>
  
<style>
.promo-banner-wrapper {
  flex: 0 0 auto;
  flex-direction: row;
  transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.promo-banner {
  cursor: default;
}

.promo-banner-text {
  border-radius: 50%;
  margin: 0 1px;
  color: #ffffff;
  font-weight: 600;
}

.promo-banner-icon {
  background-size: cover;
  background-position: center;
  margin-right: 4px;
}

@media (max-width: 766px) {
}
  
@media (min-width: 767px) and (max-width: 1024px) {
}
  
@media (min-width: 1025px) {
}
    
</style>
  