<template>
  <div class="compared-axis">
    <div class="compared-axis-name">
        {{name}}
    </div>
    <ComparedRow v-for="(row, i) in getRow" :key="i" :name="row.name" 
        :color="row.color" :value="row.value" />
  </div>
</template>

<script>
import ComparedRow from './ComparedRow'
export default {
  name: 'ComparedAxis',
  props: {
    productName: Array,
    productColor: Array,
    name: String,
    index: Number,
    getValue: Function,
  },
  computed: {
    getRow() {
      let row = []
      this.productName.forEach((p, pIndex) => {
        row.push({
            name: p.name,
            color: this.productColor[pIndex],
            value: this.getValue(this.index, pIndex)
        })
      })
      
      row.sort((a,b)=> (a.value.value < b.value.value ? 1 : -1))

      return row
    },
  },
  components: {
    ComparedRow,
  },
}
</script>

<style>
.compared-axis {
  margin: 20px 0;
  -webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.compared-axis-name {
  color: #444444;
  text-shadow: none;
  display:block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-stretch:100%;
  font-style:normal;
  font-weight: 500;
  font-size: 12px;
  text-align:center;
  padding: 12px 0;
  margin-bottom: 12px;
  -webkit-box-shadow: 0px 10px 10px -8px rgba(223, 223, 223, 0.6); 
  box-shadow: 0px 7px 8px -8px rgba(223, 223, 223, 0.6);
}

</style>