<template>
  <div class="product-compare-container view-container">
    <div class="product-compare-wrapper view-wrapper page-wrapper --tablet-break">

      <div class="content-wrapper">
        <div class="chart-container --hide-scrollbar">

          <div class="chart-wrapper" @click ="hideProductList">
            <ComparedChart :chartData="getData" :colors="getColors" :axis="axis" />
            <div class="chart-more-info-btn --flex-center">
              <button v-if="selectedProduct.length" class="btn invert" @click="handleModal">See More Details</button>
            </div>
          </div>
        </div>
      
        <div class="chart-action-container --hide-scrollbar">
          <div class="helper-text-wrapper --box-shadow-1">
            <div class="selected-list" v-if="isShow">
              Selected Cable: 
              <template v-for="(product, index) in products">
                <ProductTag :isSelected="isSelected" :key="index"
                  :name="product.name" :color="product.color"
                  :value="product.id" :remove="removeProduct" />
              </template>
            </div>
            <p v-else class="helper-text warning">
              Please select product(s) 
            </p>
          </div>
          <ProductList :products="products" :isSelected="isSelected" :add="addProduct" :remove="removeProduct"
            :isShow="isProductListShow" :hide="hideProductList" />
        </div>

      </div>

    </div>

    <ComparedModal v-if="isShow" :product-name="getProductName" :product-data="getData" :product-color="getColors" />

    <div class="toggle-sticky"
      :class="{ '--show-product' : isProductListShow, '--shake' : toggleShake }"
      @click="toggleProductList" v-touch:swipe="toggleProductList">
      <img class="icon" :src="isProductListShow ? '../../assets/icon/chevron-down.svg' : '../../assets/icon/chevron-up.svg'">
    </div>
  </div>
</template>

<script>
import ComparedChart from './components/ComparedChart.vue'
import ComparedModal from './components/ComparedModal.vue'
import ProductList from './components/ProductList.vue'
import ProductTag from './components/ProductTag.vue'

export default {
  name: 'ProductCompare',
  data: () => {
    return {
      products: [],
      axis: [],
      selectedProduct: [],
      filter: 'in-stock',
      toggleShake: false,
      fetchUrl: './data/enigma-products.json',
      isProductListShow: true,
    }
  },
  created() {
    this.fetchData(this.fetchUrl)
    this.$nextTick(() => { 

      if (this.$route.query.product) {
        this.selectedProduct = this.$route.query.product.split(',')
        if (this.selectedProduct.length) { 
          this.isProductListShow = false
          if(this.isMobile) {
            setTimeout(() => {
              this.toggleShake = true
            }, 1500)
            setTimeout(() => {
              this.toggleShake = false
            }, 3000)
          }
        }
      }

      
      if (this.$route.query.filter)
        this.filter = this.$route.query.filter
    })
  },
  computed:{ 
    filterProduct() {
      return this.products.filter(p => p.shop)
    },
    isShow() {
      return this.selectedProduct.length > 0
    },
    isMobile() {
      return window.innerWidth < 787
    },
    getData() {
      return this.products.filter(p => this.selectedProduct.includes(p.id)).map(p => p.data)
    },
    getProductName () {
      return this.products.filter(p => this.selectedProduct.includes(p.id))
    },
    getColors() {
      return this.products.filter(p => this.selectedProduct.includes(p.id)).map(p => p.color)
    },
  },
  methods: {
    fetchData(url) {
      fetch(url)
        .then(response => response.json())
        .then((data) => {
          if(data.series.length) {
            let products = []
            data.series.forEach((s) => {
              let seriesName = s.name
              s.products.forEach((p) => {
                let now = new Date()
                p.promo = p.promo.filter(p => new Date(p.start) <  now && new Date(p.end) > now)
                products.push({ ...p, series: seriesName })
              })
            })
            this.products = products.sort((a, b) => { return a.price - b.price})
          }
          if (this.products.length) {
            this.axis = (this.products[0].data.map((i) => {return i.axis}))
          }
        })
    },
    addProduct(val) {
      if(!this.selectedProduct.includes(val)) {
        this.selectedProduct.push(val)
        this.selectedProduct.sort((a, b) => { return parseInt(a) - parseInt(b)})
      }
    },
    removeProduct(val) {
      this.selectedProduct = this.selectedProduct.filter(item => item !== val)
    },
    isSelected(index) {
      return this.selectedProduct.includes(index)
    },
    handleModal(e) {
      this.$modal.show('compared-modal')
      this.isProductListShow = false
      e.preventDefault()
      e.stopPropagation()
    },
    handleFilter(val) {
      if (val !== this.filter) {
        this.filter = val
        if (this.filter === 'in-stock') {
          this.products.forEach(p => {
            if(this.selectedProduct.includes(p.id) && !p.shop) {
              this.selectedProduct = this.selectedProduct.filter(item => item !== p.id)
            }
          })
        }
      }
    },
    toggleProductList() {
      if(this.isMobile) {
        this.isProductListShow = !this.isProductListShow
      }
    },
    showProductList() {
      if(this.isMobile) {
        this.isProductListShow = true
      }
    },
    hideProductList() {
      if(this.isMobile) {
        this.isProductListShow = false
      }
    },
  },
  watch: {
    selectedProduct: function(newVal, oldVal) {
      if(!newVal.length) {
        this.$router.replace({})
        this.isProductListShow = true
      }
      else if (oldVal.length)
        this.$router.replace({ query: { product: newVal.join() } } )
    },
  },
  components: {
    ComparedChart,
    ComparedModal,
    ProductList,
    ProductTag,
  }
}
</script>

<style>


.helper-text-wrapper {
  position: relative;
  width: 100%;
  max-width: 940px;
  background-color: #ffffff;
}

.helper-text {
  font-size: 13px;
  width: 100%;
  padding: 12px 0;
  margin: 0;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.icon.product-select {
  height: 9px;
}

.icon.product-select {
  transform: rotate(90deg);
  margin-left: 5px;
}

.icon.product-select:last-child { 
  margin-left: -10px;
}

.selected-list {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  z-index: 1;
  border: 0;
  background-color: #ffffff;
  color: #444444;
  padding-left: 20px;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  height: 100%;
  flex-wrap: wrap;
}

.chart-wrapper {
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  flex-direction: column;
}

.chart-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #222222;
}

.products-wrapper {
  max-width: 500px;
}

.chart-more-info-btn {
  width: 100%;
  margin-bottom: 8px;
}

.chart-more-info-btn .btn {
  background-color: rgba(255,255,255,0.85);
  color: #444444;
  padding: 16px 0;
  font-size: 16px;
  border-radius: 32px;
  text-decoration: underline;
  text-underline-offset: 2px;
  width: calc(100% - 24px);
}

@media (max-width: 766px) {
  .chart-container {
    width: 100%;
  }

  .chart-wrapper {
    flex: 1 1 auto;
    margin-top: 20px;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .chart-more-info-btn .btn { 
    font-size: 13px;
    padding: 12px 0;
  }

  .helper-text-wrapper {
    background-color: #ffffff;
  }

  .product-compare-wrapper, .content-wrapper {
    height: 100%;
    flex-flow: column-reverse;
  }

  .toggle-sticky {
    position: fixed;
    bottom: -5px;
    width: 150px;
    background-color: #ffffff;
    color: #444444;
    font-size: 13px;
    box-sizing: border-box;
    right: calc(50% - 75px);
    border-radius: 18px 18px 0 0;
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toggle-sticky  img { 
    width: 35px;
    margin: 0;
  }

  .toggle-sticky.--show-product {
    bottom: 190px;
  }
}

@media (min-width: 767px) {
  .chart-action-container {
    flex: 0 0 50%;
    max-width: 600px;
    height: 100%;
    overflow-y: scroll;
  }
  .chart-container{
    width: 50%;
    position: sticky;
    top: 60px;
    background-color: #444444;
    max-width: 600px;
    height: 100%;
    overflow-y: scroll;
  }

  .helper-text-wrapper {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 10;
  }

  .chart-more-info-btn .btn { 
    margin-bottom: 60px;
  }
}

@media (min-width: 767px) and (max-width: 1920px) { 
  .selected-list {
    margin: 0 10px;
  }
}

@media (min-width: 1025px) {
  .view-container > .view-wrapper.product-compare-wrapper {
    height: calc(100dvh - 140px)
  }
  .chart-container{
    top: 140px;
  }
}
</style>
