<template>
  <div class="gallery-product-properties-group --flex-center">
    <template v-for="(p, index) in properties"> 
      <div class="gallery-product-properties-wrapper --flex-center" :key="index">
        <div class="gallery-product-properties"
        :style="{'background-image' : 'url(' + p.src + ')' }">
          <span class="gallery-product-properties-name --tooltip inverse --img">{{ p.name }}</span>
        </div>
      </div>
    </template> 
  </div>
</template>
    
<script>
export default {
  name: 'GalleryProperties',
  props: {
    properties: Array,
  },
  data: () => {
    return {}
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{
  },
  methods: {
  },
  watch: {
  },
}
</script>
  
<style>
.gallery-product-properties-group {
  flex: 1 1 auto;
  flex-direction: row;
}

.gallery-product-properties-wrapper {
  border-radius: 6px;
}

.gallery-product-properties {
  width: 32px;
  height: 32px;
  font-size: 12px;
  border-radius: 6px;
  cursor: default;
  background-size: cover;
  background-position: center;
}

@media (max-width: 766px) {
}
  
@media (min-width: 767px) and (max-width: 1024px) {
}
  
@media (min-width: 1025px) {
}
    
</style>
      