<template>
  <div class="gallery-wrapper" v-if="series">
    <div class="gallery-header">
      <div class="gallery-header-text-wrapper">
        <h2 class="fancy-header gallery-header-text">{{ series.name }}</h2>
        <p class="text-description gallery-header-text --font-weight-200">{{ series.description }}</p>
      </div>
    </div>
    <div class="gallery-body" :class="{ '--show-product': isShowProductInfo}">
      <GalleryDescription v-if="activeProduct" :product="activeProduct" :toggle="toggleProductInfo" />
      <div class="gallery-img-slides">
        <ImgSlides v-if="activeProduct" :images="getImages" 
          :activeId="activeProduct.id" :is-nav="true" :setActiveImg="setActiveImg">
          <div class="gallery-promo-banner-wrapper">
            <PromoBanner v-if="activeProduct.promo.length" :promo="activeProduct.promo" />
          </div>
        </ImgSlides>
      </div>
    </div>
  </div>
</template>
  
<script>
import ImgSlides from '@/public/ImgSlides.vue';
import GalleryDescription from './GalleryDescription.vue';
import PromoBanner from '@/public/PromoBanner.vue';
export default {
  name: 'GalleryWrapper',
  props: {
    series: Object,
  },
  data: () => {
    return {
      activeProduct: null,
      isShowProductInfo: false,
      products: [],
    }
  },
  created() {
    this.products = this.series.products.filter(p => p.isShow)
    this.products.sort((a, b) => { return a.value - b.value })
    this.activeProduct = this.products[0]
  },
  computed:{ 
    isMobile() {
      return window.innerWidth < 787
    },
    getImages() {
      return this.products.map(p => { 
        return { 
                  src: p.imgs[0], 
                  id: p.id 
                }
      })
    }
  },
  methods: {
    setActiveImg(id) {
      this.activeProduct = this.products.filter(img => img.id === id)[0]
    },
    toggleProductInfo() {
      if(this.isMobile) {
        this.isShowProductInfo = !this.isShowProductInfo
      }
    },
  },
  watch: {
  },
  components: {
    GalleryDescription,
    ImgSlides,
    PromoBanner,
  }
}
</script>
  
<style>

.fancy-header {
  font-weight: 200;
}

.--dark-bg .gallery-wrapper {
  color: #ffffff;
}

.gallery-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.gallery-header {
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fancy-header.gallery-header-text {
  margin: 0;
}

.text-description.gallery-header-text{
  margin-bottom: 0;
}


.gallery-body {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-y: overlay;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.gallery-body > .gallery-imageset-container { 
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.gallery-img-slides {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.gallery-promo-banner-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (max-width: 766px) {
  .fancy-header.gallery-header-text {
    font-size: 22px;
  }
  .text-description.gallery-header-text {
    font-size: 12px;
  }

  .gallery-header {
    padding: 10px;
  }

  .gallery-body {
    flex-direction: column-reverse;
    height: 100%;
    overflow-y: hidden;
    justify-content: start;
    flex: 1 1 auto;
  }

  .gallery-body .gallery-description-container {
    flex: 0 0 30%;
  }

  .gallery-body.--show-product .gallery-description-container {
    flex: 1 1 auto;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .gallery-header {
    flex: 0 0 20%;
    max-width: 1025px;
    padding: 0 100px;
    align-self: center;
    justify-content: center;
  }

  .fancy-header.gallery-header-text, .fancy-header.gallery-product-name{
    font-size: 2.8em;
  }

  .text-description.gallery-header-text {
    font-size: 1.4em;
  }

  .gallery-body {
    flex-flow: column-reverse;
    overflow-y: hidden;
    justify-content: end;
  }

  .gallery-body > .gallery-imageset-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1025px) {
  .gallery-header {
    height: 24%;
    flex: 0 0 24%;
    max-width: 1025px;
    padding: 0 100px;
    align-self: center;
  }

  .fancy-header.gallery-header-text, .fancy-header.gallery-product-name{
    font-size: 2.5em;
  }

  .text-description.gallery-header-text {
    font-size: 1.4em;
  }

  .--reverse .gallery-body {
    flex-flow: row-reverse;
  }

  .gallery-body > .gallery-imageset-container { 
    width: 65%;
    height: 100%;
  }
}
  
</style>
  