<template>
  <div class="product-list-container" :class="{ '--show-product' : isShow }">
    <!-- <div class="btn-filter-group">
      <span class="filter-text"> Filter: </span>
      <button class="btn btn-ghost" :class="{ '--selected' : filter === 'in-stock'}"
        @click="handleFilter('in-stock')">
        In-stocks
      </button>
      <button class="btn btn-ghost" :class="{ '--selected' : filter === 'all'}"
        @click="handleFilter('all')">
        All
      </button>
    </div> -->
    <div class="products-list-wrapper --hide-scrollbar">
      <ProductCard v-for="(product, index) in products" 
        :key="index" :color="product.color"
        :value="product.id" :product="product" :isSelected="isSelected"
        :add="add" :remove="remove" />
      <div class="product-card --dummy"></div>
    </div>
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
  name: 'ProductList',
  props: {
    products: Array,
    isSelected: Function,
    add: Function,
    hide: Function,
    remove: Function,
    isShow: Boolean,
  },
  data: () => {
    return {
    }
  },
  created() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
  components: {
    ProductCard,
  }
}
</script>

<style>

.product-list-container {
  justify-content: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #ffffff;
}

.btn-filter-group {
  font-size: 16px;
  top: 100px;
  background: #ffffff;
}

.btn-filter-group * {
  margin: 5px;
  margin-top: 15px;
}

.btn-filter-group .btn.btn-ghost {
  font-size: 14px;
  padding: 10px 20px;
}

@media (max-width: 766px) {

  .product-list-container {
    position: absolute;
    left: 0;
    bottom: -200px;
    height: 200px;
    width: 100dvw;
    overflow: hidden;
    z-index: 2;
    box-shadow: -8px 0px 15px 1px rgba(0,0,0,.2);
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .products-list-wrapper {
    height: 100%;
    width: calc(100% - 40px);
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
  }

  .product-card.--dummy {
    width: 15px;
  }

 .product-list-container.--show-product {
    bottom: 0;
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
}

@media (min-width: 767px) {
  .toggle-sticky {
    display: none;
  }

  .product-list-container {
    overflow: hidden;
    z-index: 2;
    box-shadow: -8px 0px 15px 1px rgba(0,0,0,.2);
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .product-card.--dummy {
    display: none;
  }

  .products-list-wrapper {
    margin-top: 25px;
    padding-bottom: 60px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-flow: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-shadow: none;
  }

  .product-list-container {
    box-shadow: none;
  }
}

@media (min-width: 767px) and (max-width: 1920px) { 
}
</style>
