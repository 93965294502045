<template>
  <div class="diy-container view-container">
    <div class="diy-wrapper view-wrapper">

      <page-wrapper :imgBg="imgBg01">
        <text-wrapper :height="textWrapperHeight" :bgColor="'rgba(0,0,0,0.5)'">
          <h2 class="fancy-header --margin-0 --font-weight-400">Coming Soon.. . </h2>
        </text-wrapper>
      </page-wrapper>
      
    </div>
  </div>
</template>
  
<script>

import PageWrapper from '@/public/PageWrapper.vue';
import TextWrapper from '@/public/TextWrapper.vue';
export default {
  name: 'DIYPage',
  data: () => {
    return {
      imgBg01: "'https://scontent.fbkk2-8.fna.fbcdn.net/v/t39.30808-6/384772115_715637060605947_2580646760063780773_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeGmXMFkvyMein-WQ7DDwqAZ8UEvwvSDQ5nxQS_C9INDmZ86cZ7HKF36OCRug0cdguxiXfwpOmAgDdTWkuHqSpoO&_nc_ohc=1YI9bnVq4SYAX_dnofV&_nc_ht=scontent.fbkk2-8.fna&oh=00_AfA2_IKN2GgqRdRBVLeJ5BJRdR4MlYlhFLYuGLI0kh0i0w&oe=655AEF2F'",
      textWrapperHeight: '60%',
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
  components: {
    PageWrapper,
    TextWrapper
},
}
</script>
  
<style>

@media (max-width: 766px) {
}

@media (min-width: 767px) {
}

</style>
  