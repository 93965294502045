<template>
  <div class="compared-row">
    <div class="compared-row-table">
      <div class="compared-row-name compared-row-item">
          {{name}}
      </div>
      <div class="compared-row-description compared-row-item">
          {{value.description}}
      </div>
    </div>
  </div>
</template>

<script>export default {
  name: 'ComparedRow',
  props: {
    name: String,
    color: String,
    value: Object,
  },
}
</script>

<style>
.compared-row {
  display: flex;
  text-shadow: none;
  font-size: 12px;
  color: #444444;
  border-bottom: 1px dotted #cccccc;
  justify-content: space-between;
  padding: 5px 0;
}

.compared-row:last-child {
  border-bottom: none;
  padding-bottom: 15px;
}

.compared-row:hover {
  background-color: rgba(236, 236, 236, 0.8);
}

.compared-row-item {
  display: inline-flex;
  padding: 5px 30px;
  width: 50%;
}

.compared-row-name {
  width: 135px;
}

.compared-row-value {
  width: 30px;
}

.compared-row-table {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.compared-row-description {
  text-align: right;
}
</style>