<template>
  <div class="text-wrapper" 
    :style="{ 'background-color' : bgColor ? bgColor : 'rgba(0,0,0,0)',
              'color': color ? color : '#ffffff',
              'height': height ? height : '100%' }">
    <slot></slot> 
  </div>
</template>
    
  <script>
export default {
  name: 'TextWrapper',
  props: {
    color: String,
    bgColor: String,
    height: String,
  },
  data: () => {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => { 
    })
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  },
}
</script>
    
<style>
  
.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
  
@media (max-width: 766px) {
}

@media (min-width: 767px) and (max-width: 1024px) {
}

@media (min-width: 1025px) {
}
  
</style>
    